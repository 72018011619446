var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("CourseStatisticsTime", {
        ref: "time",
        on: { setOptions: _vm.setOptions, getLog: _vm.getLog },
      }),
      _c("CourseTable", {
        ref: "table",
        attrs: { id: _vm.id, select: _vm.select },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }