<template>
  <div>
    <!-- <el-card shadow="never" style="overflow-y: scroll;"> -->
      <!-- <h4 style="margin-bottom: 10px">课时统计</h4> -->
      <CourseStatisticsTime
        @setOptions="setOptions"
        @getLog="getLog"
        ref="time"
      />
    <!-- </el-card> -->
    <!-- <el-card shadow="never" style="overflow-y: scroll;"> -->
      <!-- <h4 style="margin-bottom: 10px">课时交易记录</h4> -->
      <CourseTable :id="id" :select="select" ref="table" />
    <!-- </el-card> -->
  </div>
</template>
<script>
import CourseStatisticsTime from "./components/CourseStatisticsTime";
import CourseTable from "./components/CourseTable";
export default {
  components: { CourseStatisticsTime, CourseTable },
  data() {
    return {
      id: "",
      select: [],
    };
  },
  methods: {
    /**
     * 获取明细
     */
    getLog(id) {
      this.id = id;
    },
    /**
     * 设置系列下拉
     */
    setOptions(val) {
      this.select = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.outer-style {
  height: calc(100% - 50px);
  overflow: auto;
  background-color: #ffffff;
}
@import "../../style/container.scss";

/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
</style>