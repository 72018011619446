<template>
  <div>
    <el-form :inline="true" :model="searchForm">
      <div class="row-bg">
        <el-row :gutter="24">
          <el-col :lg="5" :md="24">
            <el-form-item label="课程系列：">
              <el-select
                clearable
                v-model="searchForm.courseGoodsId"
                placeholder="课程系列"
                class="w100"
              >
                <el-option
                  v-for="item in options"
                  :key="item.key"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="5" :md="24">
            <el-form-item label="交易类型：">
              <el-select
                clearable
                v-model="searchForm.flag"
                placeholder="交易类型"
                class="w100"
              >
                <el-option
                  v-for="item in flagOptions"
                  :key="item.key"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-form-item>
            <el-button type="primary" @click="getListHandler(null)"
              >查询</el-button
            >
          </el-form-item>
          <!-- <span>
          <el-button type="primary" @click="openAddModal">添加</el-button>
          <el-button @click="resetForm">重置</el-button>
          <el-button type="primary" @click="getListHandler">查询</el-button>
        </span> -->
        </el-row>
      </div>
    </el-form>
    <el-card shadow="never" style="overflow-y: scroll">
      <div slot="header" class="flex" style="align-items: center">
        <span>课时交易记录</span>
        <div class="flex_1"></div>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>
      <el-table-bar fixed :static="true">
        <el-table
          :data="tableData"
          :default-sort="{ prop: 'open_date', order: 'descending' }"
          :key="tableKey"
          fit
          :highlight-current-row="true"
        >
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <template v-if="item.label === '数量(课时)'">
                <span
                  :style="{ color: scope.row.flag == '消费' ? 'red' : 'green' }"
                  >{{ scope.row.flag == "消费" ? "-" : "+" }} </span
                ><span>{{ scope.row.amount }}</span>
              </template>
              <template v-else-if="item.label === '交易说明'">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="scope.row.explain"
                  placement="top"
                >
                  <span class="text-oneline">{{ scope.row.explain }}</span>
                </el-tooltip>
              </template>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column align="center" prop="flag" label="交易分类" />
          <el-table-column
            align="center"
            prop="courseGoodsName"
            label="课时类型"
          />
          <el-table-column align="center" prop="amount" label="数量(课时)">
            <template v-slot="scope">
              <span
                :style="{ color: scope.row.flag == '消费' ? 'red' : 'green' }"
                >{{ scope.row.flag == "消费" ? "-" : "+" }} </span
              ><span>{{ scope.row.amount }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="explain"
            label="交易说明"
            width="400"
          >
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="dark"
                :content="scope.row.explain"
                placement="top"
              >
                <span class="text-oneline">{{ scope.row.explain }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="createTime"
            label="时间"
            width="180"
          /> -->
        </el-table>
      </el-table-bar>

      <el-pagination
        :size="size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.pageNum"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </el-card>
  </div>
</template>
<script>
import { getGoodsLogList } from "@/api/businesscourse/index";
import { getCourseGoodsNameList } from "@/api/businesscourse/course";
import TextButton from "@/components/TextButton";

import {
  getStudentList,
  addStudent,
  editStudent,
  setEnable,
} from "@/api/member/student";

// 默认展示列

const baseColSetting = [
  {
    label: "交易分类",
    prop: "flag",
    state: true,
    align: "left",
    // align: "center",

    // sortable: true,
    width: "50",
  },
  {
    label: "课程账号类型",
    prop: "courseGoodsName",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "数量(课时)",
    prop: "amount",
    state: true,
    // sortable: true,
    align: "center",
    width: "100",
  },
  {
    label: "交易说明",
    prop: "explain",
    state: true,
    // sortable: true,
    align: "center",
    width: "100",
  },
  {
    label: "时间",
    prop: "createTime",
    state: true,
    // sortable: true,
    align: "center",
    width: "100",
  },
  // {
  //   label: "操作",
  //   state: true,
  //   align: "center",
  //   width: "130",
  // },
];
export default {
  props: {
    id: {
      type: String,
      default: () => "",
    },
    select: {
      type: Array,
      default: () => [],
    },
  },
  components: { TextButton },
  data() {
    return {
      tableKey: 1,
      item: {},
      title: "",
      tableData: [],
      size: "small",
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        needCount: true,
      },
      searchForm: { courseGoodsId: null, flag: null },
      statusOptions: {
        1: "启用",
        // "2":"删除",
        3: "禁用",
      },
      options: [],
      flagOptions: [
        { value: "消费", label: "消费" },
        { value: "退回", label: "退回" },
        { value: "购买", label: "购买" },
      ],
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
    };
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  watch: {
    id: {
      handler(val) {
        this.getListHandler(val);
      },
    },
    select: {
      handler(val) {
        this.options = [{ value: null, label: "全部" }, ...val];
      },
    },
  },
  created() {
    // this.getListHandler(null);
    this.getListHandler();

    // getCourseGoodsNameList().then((res) => {
    //   this.options = [
    //     { value: null, label: "全部" },
    //     ...res.body.list.map((item) => {
    //       return {
    //         label: item.courseGoodsName,
    //         value: item.id,
    //       };
    //     }),
    //   ];
    // });
  },
  methods: {
    /**
     * 刷新
     */
    search() {
      this.pageInfo.pageNum = 1;
      this.getListHandler();
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
    /**
     * 监听下拉
     */
    change(val) {
      this.getListHandler(val);
    },
    async getListHandler(id) {
      let responseData = await getGoodsLogList({
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        needCount: this.pageInfo.needCount,
        ...this.searchForm,
        courseGoodsId: id || this.searchForm.courseGoodsId,
      });
      if (responseData && responseData.state == "success") {
        this.tableData = responseData.body.list;
        this.pageInfo.total = responseData.body.total;
      }
    },
    /**
     * 获取列表
     */
    async getListHandlers() {
      let responseData = await getStudentList({
        pageNum: this.pageInfo.pageNum,
        pageSize: this.pageInfo.pageSize,
        needCount: this.pageInfo.needCount,
        ...this.searchForm,
      });
      if (responseData && responseData.state == "success") {
        this.tableData = responseData.body.list;
        this.pageInfo.total = responseData.body.total;
      }
    },
    /**
     * 获取详情
     */
    async getDetail(id) {
      return this.tableData.filter((item) => item.id == id)[0];
    },
    confirmStatus(item) {
      const { userId, state } = item;
      setEnable({
        userId,
        status: state == "1" ? 3 : 1,
      }).then(() => {
        this.getListHandler();
      });
    },
    /**
     * 重置表单
     */
    resetForm() {
      this.pageInfo.pageNum = 1;
      this.pageInfo.pageSize = 10;
      this.searchForm = {
        courseGoodsId: null,
        flag: null,
      };
      this.getListHandler();
    },
    /**
     * 查看
     */
    handleClick(item) {},
    /**
     * 页码改变
     */
    handleCurrentChange(page) {
      this.pageInfo.pageNum = page;
      this.getListHandler();
    },
    /**
     * 打开编辑弹窗
     */
    openEditModal(item) {
      // TODO 添加获取详情
      this.item = item || {};
      this.$nextTick(() => {
        this.$refs.edit.dialogVisible = true;
      });
    },
    /**
     * 打开添加弹窗
     */
    openAddModal() {
      this.$refs.add.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.add.initData();
      });
    },
    /**
     * 打开详情
     */
    openDetailModal(item) {
      this.$refs.detail.initData(item);
      this.$nextTick(() => {
        this.$refs.detail.drawer = true;
      });
    },
    /**
     * pageSize改变
     */
    handleSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.getListHandler();
    },
    /**
     * 添加成员
     */
    async addMember(value) {
      const { name, tel, pwd, loginName, state } = value;
      const response = await addStudent({ name, tel, pwd, loginName, state });
      if (response.state == "success") {
        // this.pageInfo.pageNum = 1;
        // this.pageInfo.pageSize = 10;
        this.$message.success("添加成功");
        this.getListHandler();
        this.$refs.add.dialogVisible = false;
      } else {
        this.$message.warning("添加失败");
      }
    },
    async editMember(value) {
      const { id, name, tel, pwd, state } = value;
      const response = await editStudent({
        id,
        name,
        tel,
        pwd,
        state,
      });
      if (response.state == "success") {
        this.$message.success("编辑成功");
        //   this.pageInfo.pageNum = 1;
        //   this.pageInfo.pageSize = 10;
        this.getListHandler();
        this.$refs.edit.dialogVisible = false;
      } else {
        this.$message.warning("编辑失败");
      }
    },
  },
};
</script>

<style lang="scss" scoped>

@import "../../../style/container.scss";

/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
</style>
