<template>
  <div>
    <el-card shadow="never" style="overflow-y: scroll">
      <div slot="header" class="flex" style="align-items: center">
        <span>课时统计</span>
        <div class="flex_1"></div>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>
      <el-table-bar fixed :static="true">
        <el-table
          :data="dataList"
          :default-sort="{ prop: 'open_date', order: 'descending' }"
          :key="tableKey"
          fit
          :highlight-current-row="true"
        >
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <template v-if="item.label === '课时统计'">
                <div class="slider-bar-box">
                  <el-row>
                    <el-col :span="10"
                      ><span class="slider-title">总课时</span></el-col
                    >
                    <el-col :span="14">
                      <el-tooltip
                        effect="dark"
                        :content="scope.row.buyTotalAmount.toString()"
                        placement="top"
                      >
                        <span
                          class="slider-bar"
                          :style="{
                            width:
                              (scope.row.buyTotalAmount /
                                scope.row.buyTotalAmount) *
                                100 +
                              '%',
                            backgroundColor: '#f5c319',
                          }"
                        ></span>
                      </el-tooltip>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="10">
                      <span class="slider-title">
                        报名({{ cP(scope.row, "costAmount") }})
                      </span>
                    </el-col>
                    <el-col :span="14">
                      <el-tooltip
                        effect="dark"
                        :content="scope.row.costAmount.toString()"
                        placement="top"
                      >
                        <span
                          class="slider-bar"
                          :style="{
                            width:
                              (scope.row.costAmount /
                                scope.row.buyTotalAmount) *
                                100 +
                              '%',
                            backgroundColor: 'blue',
                          }"
                        ></span>
                      </el-tooltip>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="10">
                      <span class="slider-title">
                        剩余({{ cP(scope.row, "remainAmount") }})
                      </span>
                    </el-col>
                    <el-col :span="14">
                      <el-tooltip
                        effect="dark"
                        :content="scope.row.remainAmount.toString()"
                        placement="top"
                      >
                        <span
                          class="slider-bar"
                          :style="{
                            width:
                              (scope.row.remainAmount /
                                scope.row.buyTotalAmount) *
                                100 +
                              '%',
                            backgroundColor: 'green',
                          }"
                        ></span>
                      </el-tooltip>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="10"
                      ><span class="slider-title"
                        >课消({{ cP(scope.row, "usedAmount") }})</span
                      ></el-col
                    >
                    <el-col :span="14">
                      <el-tooltip
                        effect="dark"
                        :content="scope.row.usedAmount.toString()"
                        placement="top"
                      >
                        <span
                          class="slider-bar"
                          :style="{
                            width:
                              (scope.row.usedAmount /
                                scope.row.buyTotalAmount) *
                                100 +
                              '%',
                            backgroundColor: 'pink',
                          }"
                        ></span>
                      </el-tooltip>
                    </el-col>
                  </el-row>
                </div>
              </template>
              <template v-else-if="item.label === '操作'">
                <el-button
                  type="text"
                  @click="$emit('getLog', scope.row.courseGoodsId)"
                  >明细</el-button
                >
              </template>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="课程类型" align="center" prop="courseGoodsName" />
      <el-table-column
        label="已购总课时"
        align="center"
        prop="buyTotalAmount"
      />
      <el-table-column label="已报名课时" align="center" prop="costAmount" />
      <el-table-column label="剩余课时" align="center" prop="remainAmount" />
      <el-table-column label="实际课消课时" align="center" prop="usedAmount" />
      <el-table-column
        label="课时统计"
        width="200"
        align="center"
        prop="statistic"
      >
        <template slot-scope="scope">
          <div class="slider-bar-box">
            <el-row>
              <el-col :span="10"
                ><span class="slider-title">总课时</span></el-col
              >
              <el-col :span="14">
                <el-tooltip
                  effect="dark"
                  :content="scope.row.buyTotalAmount.toString()"
                  placement="top"
                >
                  <span
                    class="slider-bar"
                    :style="{
                      width:
                        (scope.row.buyTotalAmount / scope.row.buyTotalAmount) *
                          100 +
                        '%',
                      backgroundColor: '#f5c319',
                    }"
                  ></span>
                </el-tooltip>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <span class="slider-title">
                  报名({{ cP(scope.row, "costAmount") }})
                </span>
              </el-col>
              <el-col :span="14">
                <el-tooltip
                  effect="dark"
                  :content="scope.row.costAmount.toString()"
                  placement="top"
                >
                  <span
                    class="slider-bar"
                    :style="{
                      width:
                        (scope.row.costAmount / scope.row.buyTotalAmount) *
                          100 +
                        '%',
                      backgroundColor: 'blue',
                    }"
                  ></span>
                </el-tooltip>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10">
                <span class="slider-title">
                  剩余({{ cP(scope.row, "remainAmount") }})
                </span>
              </el-col>
              <el-col :span="14">
                <el-tooltip
                  effect="dark"
                  :content="scope.row.remainAmount.toString()"
                  placement="top"
                >
                  <span
                    class="slider-bar"
                    :style="{
                      width:
                        (scope.row.remainAmount / scope.row.buyTotalAmount) *
                          100 +
                        '%',
                      backgroundColor: 'green',
                    }"
                  ></span>
                </el-tooltip>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="10"
                ><span class="slider-title"
                  >课消({{ cP(scope.row, "usedAmount") }})</span
                ></el-col
              >
              <el-col :span="14">
                <el-tooltip
                  effect="dark"
                  :content="scope.row.usedAmount.toString()"
                  placement="top"
                >
                  <span
                    class="slider-bar"
                    :style="{
                      width:
                        (scope.row.usedAmount / scope.row.buyTotalAmount) *
                          100 +
                        '%',
                      backgroundColor: 'pink',
                    }"
                  ></span>
                </el-tooltip>
              </el-col>
            </el-row>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" prop="action">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="$emit('getLog', scope.row.courseGoodsId)"
            >明细</el-button
          > -->
          <!-- <el-button
              style="margin-left: 8px"
              type="text"
              @click="openDetailModal(scope.row)"
              >查看</el-button
            > -->
          <!-- </template>
      </el-table-column> -->
        </el-table>
      </el-table-bar>
    </el-card>
  </div>
</template>
<script>
import { getGoodsList } from "@/api/businesscourse/index";
import TextButton from "@/components/TextButton";

// 默认展示列

const baseColSetting = [
  {
    label: "商户名称",
    prop: "manageName",
    state: true,
    align: "left",
    // sortable: true,
    width: "100",
  },
  {
    label: "课程类型",
    prop: "courseGoodsName",
    state: true,
    align: "center",
    // sortable: true,
    width: "100",
  },
  {
    label: "已购总课时",
    prop: "buyTotalAmount",
    state: true,
    align: "center",
    width: "100",
  },
  {
    label: "已报名课时",
    prop: "costAmount",
    state: true,
    // sortable: true,
    align: "center",
    width: "100",
  },
  {
    label: "剩余课时",
    prop: "remainAmount",
    state: true,
    // sortable: true,
    align: "center",
    width: "100",
  },
  {
    label: "实际课消课时",
    prop: "usedAmount",
    state: true,
    // sortable: true,
    align: "center",
    width: "100",
  },
  {
    label: "课时统计",
    prop: "statistic",
    state: true,
    // sortable: true,
    align: "center",
    width: "100",
  },

  {
    label: "操作",
    state: true,
    align: "center",
    width: "130",
  },
];
export default {
  components: { TextButton },
  data() {
    return {
      tableKey: 1,
      size: "small",
      dataList: [],
      colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
      baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
    };
  },
  mounted() {
    this.getList();
    
    
  },
  computed: {
    defaultHeader() {
      return this.colSetting.filter((item) => item.state);
    },
  },
  methods: {
    getList(){
      getGoodsList().then((res) => {
        if (res.state == "success") {
          this.dataList = res.body.list;
          const select = res.body.list.map((i) => ({
            value: i.courseGoodsId,
            label: i.courseGoodsName,
          }));
          this.$emit("setOptions", select);
        }
      });
    },
    /**
     * 查看
     */
    openDetailModal() {
      this.$message.warning("根据需求添加");
    },
    /**
     * 计算百分比 computed percent
     */
    cP(item, str) {
      return (item[str] / item.buyTotalAmount).toFixed(2) * 100 + "%";
    },
    /**
     * 刷新
     */
    search() {
      // this.pageInfo.pageNum = 1;
    this.getList();
      // this.getGoodsList();
    },
    update(data) {
      this.tableKey = this.tableKey + 1;
      if (data.lenght !== 0) {
        this.colSetting.forEach((item) => {
          item.state = false;
          data.forEach((ele) => {
            if (ele === item.label && !item.state) {
              item.state = true;
            }
          });
        });
      } else {
        this.colSetting = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.slider-title {
  line-height: 20px;
  font-size: 12px;
}
.slider-bar-box {
  text-align: left;
}
.slider-bar {
  line-height: 20px;
  display: inline-block;
  width: 100%;
  height: 6px;
  border-radius: 5px;
  background-color: #f5c319;
}

.el-slider__bar {
  background-color: #f5c319 !important;
}
</style>